<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Enhance Delivery Business with<span style="color:#00c8d8;"> Proof of Delivery App</span></h1>
                <div>
                    <p>Managing and optimising the operations of delivery businesses can be a complex task, making it challenging to stay organised and efficient. Besides, we understand what proof of delivery means to delivery companies to establish trust and transparency. Therefore, Record Time provides innovative solutions to overcome these challenges. A valuable software in this regard is the proof of delivery app. An electronic proof of delivery docket, along with other features, helps businesses improve their delivery process. With Record TIME’s <a href="https://recordtime.com.au/blog/revolutionising-delivery-business-with-proof-of-delivery-software">proof of delivery software</a>, delivery businesses can enhance customer satisfaction, streamline their delivery service, and achieve high performance. Resulting, in peace of mind for both the company and its clients.</p>
                </div>
                <br><br>
                <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/blog/revolutionising-delivery-business-with-proof-of-delivery-software')" class="mr-7 btn-1" elevation="0">
                    Read More
                </v-btn>
                <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/proof-of-delivery.webp`" alt="Create proof of delivery dockets using record time delivery app"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container">           
                <div class="left-blog1">
                    <h2>Digital Revolution in Delivery Business Operations: Create Paperless Proof of Delivery Dockets</h2>
                    <p style="text-align:left;">With Record TIME, delivery companies can bid farewell to traditional paper-based proof of delivery and embrace a digital solution. It provides track of real time delivery and optimises the supply chain for your delivery business. In addition, Record TIME’s electronic proof of delivery (ePOD) allows delivery drivers to capture electronic signatures on a delivery receipt and instant photos on proof of delivery dockets along with safe delivery notifications or messages.
                    <br>As a result, you can reduce the hours spent on manual data entry by storing all delivery records digitally. Furthermore, our delivery app enables businesses to maintain a comprehensive database of delivery details. The system captures and stores all pertinent data, from timestamps and recipient information to special instructions and notes. Users can easily access and search the centralised repository of delivery information. This delivery management system facilitates effective record management and minimises the risk of information loss. The given image shows an example of the delivery docket.</p>

                </div>
                <div class="right-blog1">
                    <img :src="`${publicPath}assets/whoweserve/electronic-proof-of-delivery-dockets.webp`" alt="electronic proof of delivery">
                </div>
                <div style="clear:both;"></div>
            </div>
            <br>
            <h2>Make Sure that Your Drivers Send Proof of Delivery</h2> <br/>
            <h3>Electronic Signatures, and Photo Documentation</h3><br/>
            <p>Record TIME ensures the accuracy and integrity of delivery operations through different measures. With access to our electronic proof of delivery app, delivery drivers can conveniently access delivery details (for instance: products or services) on any mobile device while on the go. Our delivery management software allows you to track deliveries in real time and minimises errors associated with paperwork. Rapid data exchange enables faster delivery times. In addition to productivity, the system saves money by reducing paperwork and enhancing dispute-resolution processes. Customers can track their multiple deliveries and receive electronic proof of delivery, which improves their whole experience. <br/>Our thorough electronic records are also critical in resolving issues as quickly as possible. This technology not only boosts user experience but also has a substantial impact on a company's productivity.
Different features in Record TIME's mobile app not only enhance customer experience but also provide a sense of trust and transparency between the delivery company and its customers. Whether you are in the food delivery business, medical transportation business, or logistics delivery business, Record TIME is the perfect solution for proof of delivery with real time tracking. </p>
        </v-container>
        <v-container>
        <div class="gray-container"><br/>
                      <div style="display: inline-table;margin-top:30px" class="list">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/whoweserve/photo.webp`" alt="capture instant photo and add in your electronic pod">
                            <h2>Photo </h2>
                            <p>The primary benefit of epod is that the delivery driver has the option to take photos using the app. Furthermore, users can capture images of the recipient, the package, or the location where the package was delivered using the pod app.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/whoweserve/signature.webp`" alt="add electronic signature in your electronic proof of delivery">
                            <h2>Signature </h2>
                            <p>The driver can capture an electronic signature from the recipient using Record TIME. Then, the system saves this electronic signature along with the other information, giving proof of delivery.</p>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <img :src="`${publicPath}assets/whoweserve/note.webp`" alt="attach note in epod app">
                            <h2>Note</h2>
                            <p>The delivery driver can write a note in the app to provide additional details or descriptions related to the order's delivery. This feature allows them to record specific information relevant to the delivery process.</p>
                        </div>
                    </div>
                    </div>
            </div>
        </v-container>
            <br><br>
            <v-container>
            <h2 style="text-align:center;">Get Complete visibility on Delivery Details With Proof of Delivery App</h2>
            <br>
           <center> <img :src="`${publicPath}assets/whoweserve/why-electronic-proof-of-delivery-dockets.webp`" alt="Proof of delivery app of Record TIME" style="width: 100%;"></center>
        </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>

import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Proof of Delivery App to Streamline Delivery Service Operations',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Proof of Delivery App to Enhance Delivery Business with Record TIME. E-signature, Visual Evidence of Delivery with Proof for Businesses.'},
      {name: 'keyword', content: 'proof of delivery app'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/proof-of-delivery-app' }
    
    ]
  
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
